@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 34 37% 98%;
    /*--background: 0 0% 100%;*/
    --foreground: 222.2 84% 4.9%;

    --card: 0, 0%, 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 225 14% 95%;
    --muted-foreground: 210 13% 13%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 240, 100%, 10%, 0.06;
    --ring: 231, 10%, 75%;

    --radius: 0.5rem;


    /* Swiper  */

    /* Swiper Navigation */
    --swiper-navigation-color: hsl(var(--primary));

    /* Swiper Pagination */
    --swiper-pagination-color: hsl(var(--primary));
    --swiper-pagination-bullet-inactive-color: hsl(var(--muted-foreground));

    --purple-2: color(display-p3 0.983 0.971 0.993);
    --blue-3: color(display-p3 0.912 0.956 0.991);
    --sky-1: color(display-p3 0.98 0.995 0.999);
    --sky-2: color(display-p3 0.953 0.98 0.99);
    --sky-3: color(display-p3 0.899 0.963 0.989);
    --pink-1: color(display-p3 0.998 0.989 0.996);
    --pink-2: color(display-p3 0.992 0.97 0.985);
    --pink-3: color(display-p3 0.981 0.917 0.96);
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2, 84%, 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 211, 66%, 92%, 0.06 ;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground font-hand w-[100vw] overflow-x-hidden;
    /*background: linear-gradient(to right bottom, rgba(199, 210, 254, 0.1), rgba(254, 202, 202, 0.1), rgba(254, 249, 195, 0.1));*/
  }
}

.rt-Box {
  width: 100vw; position: absolute; top: 0px; bottom: 0px; background-repeat: no-repeat; background-image: radial-gradient(circle 800px at 700px 200px, var(--purple-2), transparent),
radial-gradient(circle 600px at calc(100% - 300px) 300px, var(--blue-3), transparent),
radial-gradient(circle 800px at right center, var(--sky-3), transparent),
radial-gradient(circle 800px at right bottom, var(--sky-1), transparent),
radial-gradient(circle 800px at calc(50% - 600px) calc(100% - 100px), var(--pink-3), var(--pink-1), transparent);
}

@font-face {
  font-family: 'AdobeTextPro-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('AdobeTextPro-Regular'), url('./AdobeTextPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'AdobeTextPro-It';
  font-style: normal;
  font-weight: normal;
  src: local('AdobeTextPro-It'), url('./AdobeTextPro-It.woff') format('woff');
}

@font-face {
  font-family: 'AdobeTextPro-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('AdobeTextPro-Bold'), url('./AdobeTextPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'AdobeTextPro-BoldIt';
  font-style: normal;
  font-weight: normal;
  src: local('AdobeTextPro-BoldIt'), url('./AdobeTextPro-BoldIt.woff') format('woff');
}

@font-face {
  font-family: 'AdobeTextPro-Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('AdobeTextPro-Semibold'), url('./AdobeTextPro-Semibold.woff') format('woff');
}


@font-face {
  font-family: 'AdobeTextPro-SemiboldIt';
  font-style: normal;
  font-weight: normal;
  src: local('AdobeTextPro-SemiboldIt'), url('./AdobeTextPro-SemiboldIt.woff') format('woff');
}
