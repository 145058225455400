:root {
  --novel-black: rgb(0 0 0);
  --novel-white: rgb(255 255 255);
  --novel-stone-50: rgb(250 250 249);
  --novel-stone-100: rgb(245 245 244);
  --novel-stone-200: rgb(231 229 228);
  --novel-stone-300: rgb(214 211 209);
  --novel-stone-400: rgb(168 162 158);
  --novel-stone-500: rgb(120 113 108);
  --novel-stone-600: rgb(87 83 78);
  --novel-stone-700: rgb(68 64 60);
  --novel-stone-800: rgb(41 37 36);
  --novel-stone-900: rgb(28 25 23);

  --novel-highlight-default: #ffffff;
  --novel-highlight-purple: #f6f3f8;
  --novel-highlight-red: #fdebeb;
  --novel-highlight-yellow: #fbf4a2;
  --novel-highlight-blue: #c1ecf9;
  --novel-highlight-green: #acf79f;
  --novel-highlight-orange: #faebdd;
  --novel-highlight-pink: #faf1f5;
  --novel-highlight-gray: #f1f1ef;
}

.dark-theme {
  --novel-black: rgb(255 255 255);
  --novel-white: rgb(25 25 25);
  --novel-stone-50: rgb(35 35 34);
  --novel-stone-100: rgb(41 37 36);
  --novel-stone-200: rgb(66 69 71);
  --novel-stone-300: rgb(112 118 123);
  --novel-stone-400: rgb(160 167 173);
  --novel-stone-500: rgb(193 199 204);
  --novel-stone-600: rgb(212 217 221);
  --novel-stone-700: rgb(229 232 235);
  --novel-stone-800: rgb(232 234 235);
  --novel-stone-900: rgb(240, 240, 241);

  --novel-highlight-default: #000000;
  --novel-highlight-purple: #3f2c4b;
  --novel-highlight-red: #5c1a1a;
  --novel-highlight-yellow: #5c4b1a;
  --novel-highlight-blue: #1a3d5c;
  --novel-highlight-green: #1a5c20;
  --novel-highlight-orange: #5c3a1a;
  --novel-highlight-pink: #5c1a3a;
  --novel-highlight-gray: #3a3a3a;
}
.editor .ProseMirror {
  @apply p-5 px-4 sm:px-6;
}

.ProseMirror h1 {
  font-weight: 600;
  font-size: 1.875em;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}

.ProseMirror > h1 {
  padding-top: 0;
}

.ProseMirror h2 {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}

.ProseMirror h3 {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}

.ProseMirror h4 {
  font-weight: 500;
  font-size: 1.2em;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}

.ProseMirror h5 {
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}

.ProseMirror p {
  word-break: break-word;
  margin-block-start: 0;
  margin-block-end: 0;
}

.ProseMirror blockquote {
  border-left: 3px solid;
  padding-left: 13px;
  padding-right: 0.9em;
  margin-top: 8px;
  margin-bottom: 4px;
  font-size: 1.2em;
  line-height: 1.5;
  max-width: 676px;
}

.ProseMirror hr {
  border: none;
  padding: 2px 0;
  padding-top: 7px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);
  max-width: 676px !important;
}

.ProseMirror code {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  line-height: normal;
  background: rgba(135, 131, 120, 0.15);
  color: #eb5757;
  border-radius: 3px;
  font-size: 85%;
  padding: 0.2em 0.4em;
}

.ProseMirror pre code {
  background: none;
}

.ProseMirror pre {
  line-height: normal;
  border-radius: 3px;
  font-size: 85%;
  padding: 30px 16px;
  margin: 16px 0;
  background: rgb(247, 246, 243) none repeat scroll 0% 0%;
}

.ProseMirror a {
  color: inherit;
  opacity: 0.7;
  transition: opacity 100ms ease-in;
  text-decoration: none;
  background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="1" height="1"%3E%3Crect x="0" y="0" width="1" height="0.3" fill="rgb(55, 53, 47)"/%3E%3C/svg%3E')
    left bottom repeat-x;
}

.ProseMirror a:hover {
  opacity: 1;
}

.ProseMirror .node-imageNode {
  display: block;
  margin: 0.25em 0;
  height: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 100%;
}

.ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--novel-stone-400);
  pointer-events: none;
  height: 0;
}
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--novel-stone-400);
  pointer-events: none;
  height: 0;
}

/* Custom image styles */

.ProseMirror img {
  transition: filter 0.1s ease-in-out;
}

.ProseMirror img :hover {
  cursor: pointer;
  filter: brightness(90%);
}

.editor .ProseMirror img.ProseMirror-selectednode {
  outline: 3px solid #5abbf7;
  filter: brightness(90%);
}

.img-placeholder {
  position: relative;
}
.img-placeholder:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 3px solid var(--novel-stone-200);
  border-top-color: var(--novel-stone-800);
  animation: spinning 0.6s linear infinite;
}

@keyframes spinning {
  to {
    transform: rotate(360deg);
  }
}

/* Custom TODO list checkboxes – shoutout to this awesome tutorial: https://moderncss.dev/pure-css-custom-checkbox-style/ */

ul[data-type='taskList'] li > label {
  margin-right: 0.2rem;
  user-select: none;
}

@media screen and (max-width: 768px) {
  ul[data-type='taskList'] li > label {
    margin-right: 0.5rem;
  }
}

ul[data-type='taskList'] li > label input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--novel-white);
  margin: 0;
  cursor: pointer;
  width: 1.2em;
  top: 5px;
  height: 1.2em;
  position: relative;
  border: 2px solid var(--novel-stone-900);
  margin-right: 0.3rem;
  display: grid;
  place-content: center;
}

ul[data-type='taskList'] li > label input[type='checkbox']:hover {
  background-color: var(--novel-stone-50);
}

ul[data-type='taskList'] li > label input[type='checkbox']:active {
  background-color: var(--novel-stone-200);
}

ul[data-type='taskList'] li > label input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em;
  transform-origin: center;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

ul[data-type='taskList'] li > label input[type='checkbox']:checked::before {
  transform: scale(1);
}

ul[data-type='taskList'] li[data-checked='true'] > div > p {
  color: var(--novel-stone-400);
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

/* Overwrite tippy-box original max-width */

.tippy-box {
  max-width: 400px !important;
}

.editor .ProseMirror:not(.dragging) .ProseMirror-selectednode {
  outline: none !important;
  background-color: var(--novel-highlight-blue);
  transition: background-color 0.2s;
  box-shadow: none;
}

.drag-handle {
  position: fixed;
  opacity: 1;
  transition: opacity ease-in 0.2s;
  border-radius: 0.25rem;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(0, 0, 0, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.5rem;
  z-index: 50;
  cursor: grab;

  @media screen and (max-width: 600px) {
    display: none;
    pointer-events: none;
  }
}

.drag-handle:hover {
  background-color: var(--novel-stone-100);
  transition: background-color 0.2s;
}

.drag-handle:active {
  background-color: var(--novel-stone-200);
  transition: background-color 0.2s;
}

.drag-handle.hide {
  opacity: 0;
  pointer-events: none;
}

.dark-theme .drag-handle {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(255, 255, 255, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
}
